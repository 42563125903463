// Router.set('/', 'home');
// Router.set('/', 'home', { middleware: ['auth'] });
// Router.set('/', 'home', { name: 'home' });
// Router.group({ prefix: 'users', middleware: ['auth']}, ({ set }) => {
//     set('password', 'home', { middleware: ['acl', 'auth'] });
// });

Router.set('/', 'Login');

// @SW_ROUTES - START
Router.set('/login', 'Login');
Router.set('/installations', 'Installations', { middleware: ['auth'] });
Router.set('/users', 'Users', { middleware: ['auth'] });
Router.set('/companies', 'Companies', { middleware: ['auth'] });
Router.set('/notifications', 'Notifications', { middleware: ['auth'] });
Router.set('/notifications/create', 'Notifications-Form', { middleware: ['auth'] });
Router.set('/notifications/:id/view', 'Notifications-Form', { middleware: ['auth'] });
Router.set('/panel', 'Dashboard', { middleware: ['auth'] });
Router.set('/installations/create', 'Installations-Form', { middleware: ['auth'] });
Router.set('/installations/:id/edit', 'Installations-Form', { middleware: ['auth'] });
Router.set('/installations/:id/schedule', 'Schedule', { middleware: ['auth'] });
Router.set('/users/create', 'Users-Form', { middleware: ['auth'] });
Router.set('/users/:id/edit', 'Users-Form', { middleware: ['auth'] });
Router.set('/companies/create', 'Companies-Form', { middleware: ['auth'] });
Router.set('/companies/:id/edit', 'Companies-Form', { middleware: ['auth'] });
Router.set('/sectors', 'Sectors', { middleware: ['auth'] });
Router.set('/sectors/create', 'Sectors-Form', { middleware: ['auth'] });
Router.set('/sectors/:id/edit', 'Sectors-Form', { middleware: ['auth'] });
Router.set('/inspections/:id/edit', 'Inspection-Form', { middleware: ['auth'] });
Router.set('/inspection-reports', 'Inspection-Report', { middleware: ['auth'] });
Router.set('/vistoria/:code', 'Inspection');
Router.set('/update/password', 'Password-Required', { middleware: ['auth'] });
Router.set('/denatran', 'Denatran', { middleware: ['auth'] });
Router.set('/denatran/:id/rules', 'Denatran-Rules', { middleware: ['auth'] });
Router.set('/responses', 'Responses', { middleware: ['auth'] });
Router.set('/responses/create', 'Responses-Form', { middleware: ['auth'] });
Router.set('/responses/:id/edit', 'Responses-Form', { middleware: ['auth'] });

// @SW_ROUTES - END

Router.set('*', '404');
